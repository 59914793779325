/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { SelectComponent } from '../../core/select/select.component';
import { BaseFormFieldComponent } from '../base-form-field.component';
import { FormFieldWrapperComponent } from '../form-field-wrapper.component';

@Component({
  selector: 'do-select-field',
  template: `
    <do-form-field-wrapper
      [label]="label"
      [shouldShowError]="shouldShowError()"
      [errors]="errors()"
      [tooltip]="tooltip"
      [showHint]="showHint"
    >
      <do-select
        [hasError]="shouldShowError()"
        [formControl]="formControl"
        [bindValue]="bindValue"
        [clearable]="clearable"
        [bindLabel]="bindLabel"
        [multiple]="multiple"
        [placeholder]="placeholder"
        [searchable]="searchable"
        [items]="items"
        [canAdd]="canAdd"
        [canView]="canView"
        [readOnly]="readOnly"
        [appendTo]="appendTo"
      >
      </do-select>
    </do-form-field-wrapper>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormFieldWrapperComponent, ReactiveFormsModule, SelectComponent],
})
export class SelectFieldComponent extends BaseFormFieldComponent {
  @Input()
  placeholder = '';

  @Input()
  bindValue? = 'value';

  @Input()
  bindLabel? = 'description';

  @Input()
  multiple = false;

  @Input()
  searchable = false;

  @Input()
  clearable = false;

  @Input()
  items!: any[];

  @Input()
  canAdd? = false;

  @Input()
  canView? = false;

  @Input()
  appendTo?: string;

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.items) {
      throw new Error('items unspecified for autocomplete');
    }
  }
}

import { UserDto } from '@do/common-dto';
import { signalStore, withState } from '@ngrx/signals';

import { withStoreMethods } from './auth.methods';

export interface LoginFormValue {
  username: string;
  password: string;
  rememberMe: boolean;
  newPassword: string;
  confirmPassword: string;
}

export const loginFormInitialState: LoginFormValue = {
  username: '',
  password: '',
  rememberMe: false,
  newPassword: '',
  confirmPassword: '',
};

export interface AuthState {
  loginForm: LoginFormValue;
  loggedUser: UserDto | null;
  permissions: string[];
  authError: string;
  resetPasswordEmailSent: boolean;
  userMustResetPassword: boolean;
}

const initialState: AuthState = {
  loginForm: loginFormInitialState,
  loggedUser: null,
  permissions: [],
  userMustResetPassword: false,
  resetPasswordEmailSent: false,
  authError: '',
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  // actions & effects
  withStoreMethods()
);

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'do-toast',
  styleUrl: 'toast.component.scss',
  template: `
    <div
      class="d-flex flex-grow-1 align-items-center"
      [style.display]="state.value === 'inactive' ? 'none' : ''"
    >
      @if(isSuccess()){
      <span class="material-symbols-outlined me-2"> check_circle </span>
      } @else if (isError()) {
      <span class="material-symbols-outlined me-2"> error </span>
      } @else if (isWarning()) {
      <span class="material-symbols-outlined me-2"> warning </span>
      }
      <div class="flex-grow-1 d-flex flex-column justify-content-center">
        <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
        >
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div>
      </div>
      <div class="text-end">
        <span
          *ngIf="options.closeButton"
          class="material-symbols-outlined cursor-pointer"
          (click)="remove()"
          >close</span
        >
      </div>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  preserveWhitespaces: false,
  standalone: true,
  imports: [CommonModule],
})
export class ToastComponent extends Toast {
  // constructor is only necessary when not using AoT
  constructor(toastrService: ToastrService, toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  isSuccess = () => this.options.toastClass.includes('toast-success');
  isError = () => this.options.toastClass.includes('toast-error');
  isWarning = () => this.options.toastClass.includes('toast-warning');
}

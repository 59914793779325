import { firstValueFrom, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiClientConfig } from '@do/app-api-client';
import { SKIP_INTERCEPT_HEADER } from '@do/app-error';
import { TokenDto, UserDto } from '@do/common-dto';

const STORAGE_LOCAL_ACCESS_TOKEN_KEY = 'DO:LOCAL_JWT_ACCESS_TOKEN';
const STORAGE_LOCAL_REFRESH_TOKEN_KEY = 'DO:LOCAL_JWT_REFRESH_TOKEN';
const STORAGE_TMP_URL_KEY = 'DO:TMP_URL';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelper = new JwtHelperService();
  constructor(
    private httpClient: HttpClient,
    private apiClientConfig: ApiClientConfig
  ) {
    // this.oidcSecurityService.checkAuth().subscribe(() => );
  }

  me() {
    return this.httpClient.get<UserDto>(
      this.apiClientConfig.apiEndpoint + '/auth/me'
    );
  }

  mePromise() {
    return firstValueFrom(this.me());
  }

  updateMePromise(fields: { name: string; surname: string; language: string }) {
    return firstValueFrom(
      this.httpClient.post<UserDto>(
        this.apiClientConfig.apiEndpoint + '/auth/me',
        fields
      )
    );
  }

  // get tokenProvider$() {
  //   return this.oidcSecurityService.isAuthenticated$.pipe(
  //     map((result) => {
  //       if (result.isAuthenticated) {
  //         return TokenProvider.AAD;
  //       } else {
  //         const local_token = this.getLocalAccessToken();
  //         if (!local_token) return null;
  //         const isExpired = this.isJwtTokenExpired(local_token);
  //         if (isExpired) return null;
  //         else return TokenProvider.Internal;
  //       }
  //     }),
  //     catchError(() => of(null))
  //   );
  // }

  get signedIn$() {
    return this.accessToken$.pipe(
      map((token) => {
        if (!token) return false;
        const isExpired = this.isJwtTokenExpired(token);

        if (isExpired) return false;
        return true;
      })
    );
  }

  get accessToken$() {
    return of(this.getLocalAccessToken());
    // return this.tokenProvider$.pipe(
    //   switchMap((provider) => {
    //     if (provider === TokenProvider.Internal) {
    //       return of(this.getLocalAccessToken());
    //     } else {
    //       return this.oidcSecurityService.getAccessToken();
    //     }
    //   })
    // );
  }

  checkAuth() {
    return this.accessToken$.pipe(
      switchMap((token) => {
        if (!token) return of(false);
        if (this.isJwtTokenExpired(token)) {
          return this.refreshTokens().pipe(
            map(() => {
              return true;
            }),
            catchError((err) => {
              console.log('refresh token error ', err);
              return of(false);
            })
          );
        }
        return of(true);
      }),
      first()
    );
  }

  confirmUser(
    username: string,
    tmpPassword: string,
    rememberMe: boolean,
    newPassword: string,
    name: string,
    surname: string
  ) {
    return this.httpClient
      .post<TokenDto>(
        this.apiClientConfig.apiEndpoint + '/auth/confirm-user',
        {
          email: username,
          tmpPassword,
          name,
          surname,
          rememberMe,
          newPassword,
        },
        {
          headers: new HttpHeaders().set(SKIP_INTERCEPT_HEADER, '1'),
        }
      )
      .pipe(
        tap((result) => {
          this.storeTokenResult(result);
        })
      );
  }

  login(
    username: string,
    password: string,
    rememberMe: boolean,
    newPassword?: string
  ) {
    return this.httpClient
      .post<TokenDto>(
        this.apiClientConfig.apiEndpoint + '/auth/sign-in',
        {
          email: username,
          password,
          rememberMe,
          newPassword,
        },
        {
          headers: new HttpHeaders().set(SKIP_INTERCEPT_HEADER, '1'),
        }
      )
      .pipe(
        tap((result) => {
          this.storeTokenResult(result);
        })
      );
  }

  logout() {
    return this.httpClient
      .get<void>(this.apiClientConfig.apiEndpoint + '/auth/logout')
      .pipe(
        switchMap(() => {
          this.logoutLocal();
          return of(true);
        }),
        catchError(() => of(this.logoutLocal()))
      );
  }

  logoutLocal() {
    this.clearStorage();
  }

  refreshTokens() {
    return this.httpClient
      .get<TokenDto>(this.apiClientConfig.apiEndpoint + '/auth/refresh', {
        headers: {
          Authorization: 'Bearer ' + this.getLocalRefreshToken(),
        },
      })
      .pipe(
        tap((result) => {
          this.storeTokenResult(result);
        })
      );
  }

  pushTmpUrl(url: string) {
    sessionStorage.setItem(STORAGE_TMP_URL_KEY, url);
  }

  popTmpUrl() {
    const result = sessionStorage.getItem(STORAGE_TMP_URL_KEY);
    sessionStorage.removeItem(STORAGE_TMP_URL_KEY);
    return result;
  }

  forgotPassword(username: string) {
    return this.httpClient.post(
      this.apiClientConfig.apiEndpoint + '/auth/forgot-password',
      {
        email: username,
      },
      {
        headers: new HttpHeaders().set(SKIP_INTERCEPT_HEADER, '1'),
      }
    );
  }

  resetPassword(token: string, newPassword: string) {
    return this.httpClient.post(
      this.apiClientConfig.apiEndpoint + '/auth/reset-password',
      { newPassword },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        }).set(SKIP_INTERCEPT_HEADER, '1'),
      }
    );
  }

  changePassword(password: string, newPassword: string) {
    return this.httpClient.post(
      this.apiClientConfig.apiEndpoint + '/auth/change-password',
      { password, newPassword }
    );
  }

  private isJwtTokenExpired(jwt: string) {
    return this.jwtHelper.isTokenExpired(jwt);
  }

  private getLocalAccessToken() {
    return localStorage.getItem(STORAGE_LOCAL_ACCESS_TOKEN_KEY);
  }

  private setLocalAccessToken(token: string) {
    localStorage.setItem(STORAGE_LOCAL_ACCESS_TOKEN_KEY, token);
  }

  private setLocalRefreshToken(token: string) {
    localStorage.setItem(STORAGE_LOCAL_REFRESH_TOKEN_KEY, token);
  }

  private getLocalRefreshToken() {
    return localStorage.getItem(STORAGE_LOCAL_REFRESH_TOKEN_KEY);
  }

  private storeTokenResult(result: TokenDto) {
    this.setLocalAccessToken(result.accessToken);
    if (result.refreshToken) {
      this.setLocalRefreshToken(result.refreshToken);
    }
  }

  private clearStorage() {
    localStorage.removeItem(STORAGE_LOCAL_ACCESS_TOKEN_KEY);
    localStorage.removeItem(STORAGE_LOCAL_REFRESH_TOKEN_KEY);
  }
}

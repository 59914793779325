import { inject } from '@angular/core';
import { TOAST_SERVICE_TOKEN } from '@do/app-common';
import { marker } from '@do/ngx-translate-extract-marker';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { ErrorState } from './error.state';

export function withStoreMethods() {
  return signalStoreFeature(
    { state: type<ErrorState>() },
    withMethods(
      (
        state,
        toastService = inject(TOAST_SERVICE_TOKEN),
        translateService = inject(TranslateService)
      ) => ({
        addError(error: Error) {
          // reducer
          patchState(state, {
            errors: [...state.errors(), error],
          });
          // effect
          console.error('Unmanaged error:', error);
        },
        addApiErrorMsg(errorMessage: string, errorLog?: any) {
          // reducer
          patchState(state, {
            lastApiErrorMessage: errorMessage,
          });
          // effect
          if (errorMessage) {
            toastService.errorNotification(
              translateService.instant(errorMessage)
            );
          }
          if (errorLog) {
            console.error(errorLog);
          }
        },
        addLazyNavigationErrorMsg(error: Error) {
          // reducer
          patchState(state, {
            errors: [...state.errors(), error],
          });
          // effect

          toastService.errorNotification(
            translateService.instant(
              marker('Failed to load page. Check your internet connection.')
            )
          );
        },
      })
    )
  );
}

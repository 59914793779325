import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const equalTo = (
  fieldName: string,
  comparandFieldName: string,
  comparandControl: AbstractControl
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return comparandControl.value !== control.value
      ? {
          equalTo: {
            name: fieldName,
            comparandName: comparandFieldName,
          },
        }
      : null;
  };
};

export const lessThan = (
  fieldName: string,
  comparandFieldName: string,
  comparandControl: AbstractControl
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return comparandControl.value < control.value
      ? {
          lessThan: {
            name: fieldName,
            comparandName: comparandFieldName,
          },
        }
      : null;
  };
};

export const moreThan = (
  fieldName: string,
  comparandFieldName: string,
  comparandControl: AbstractControl
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return comparandControl.value > control.value
      ? {
          moreThan: {
            name: fieldName,
            comparandName: comparandFieldName,
          },
        }
      : null;
  };
};

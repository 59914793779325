import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ROUTER_FACADE_TOKEN } from '@do/app-common';
import { equalTo, FormsErrorPipe } from '@do/app-forms';
import { ButtonComponent, TextFieldComponent } from '@do/app-ui-kit';
import { passwordRegex } from '@do/common-utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AuthStore } from '../+state/auth.store';
import { PasswordHintComponent } from '../components/password-hint.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TextFieldComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FormsErrorPipe,
    PasswordHintComponent,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ``,
  template: `
    <div class="modal-header">
      <span class="modal-title fw-bold" translate>Change password </span>

      <span
        class="material-symbols-outlined text-primary cursor-pointer"
        (click)="activeModal.close(null)"
      >
        cancel
      </span>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <do-text-field
            [label]="'Current password' | translate"
            [controlType]="'password'"
            [formControl]="form.controls.password"
            [errors]="form.controls.password.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>
        <div class="mb-3">
          <do-text-field
            [label]="'New password' | translate"
            [controlType]="'password'"
            [formControl]="form.controls.newPassword"
            [errors]="form.controls.newPassword.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>
        <div class="mb-3">
          <do-text-field
            [label]="'Confirm new password' | translate"
            [controlType]="'password'"
            [formControl]="form.controls.confirmNewPassword"
            [errors]="form.controls.confirmNewPassword.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>

        <do-auth-password-hint></do-auth-password-hint>
      </form>
    </div>
    <div class="modal-footer">
      <do-button
        class="me-2"
        [cssClasses]="'btn-link'"
        [text]="'Cancel' | translate"
        (clicked)="activeModal.close(null)"
      ></do-button>

      <do-button
        class="me-2"
        [cssClasses]="'btn-primary'"
        [icon]="'done'"
        [text]="'Save' | translate"
        (clicked)="save()"
      ></do-button>
    </div>
  `,
})
export class ChangePasswordPopupContainer {
  fb = inject(FormBuilder);
  routerFacade = inject(ROUTER_FACADE_TOKEN);
  activeModal = inject(NgbActiveModal);
  authStore = inject(AuthStore);

  formIsSubmitted = signal(false);

  translateService = inject(TranslateService);

  private newPasswordControl = new FormControl('', [
    Validators.required,
    Validators.pattern(passwordRegex),
  ]);

  form = this.fb.group({
    password: ['', Validators.required],
    newPassword: this.newPasswordControl,
    confirmNewPassword: [
      '',
      equalTo(
        this.translateService.instant('Confirm new password'),
        this.translateService.instant('New password'),
        this.newPasswordControl
      ),
    ],
  });

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    this.formIsSubmitted.set(true);
    if (this.form.valid) {
      this.activeModal.close({
        password: this.form.value.password,
        newPassword: this.form.value.newPassword,
      });
    }
  }
}

import 'moment/locale/it';

import moment from 'moment';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-GB';
import localeIt from '@angular/common/locales/it';
import { inject, Injectable } from '@angular/core';
import { DefaultLanguage, SupportedLanguages } from '@do/common-interfaces';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  private translateService = inject(TranslateService);

  private readonly LOCALE_STORAGE_KEY = 'do_locale';

  constructor() {
    registerLocaleData(localeIt);
    registerLocaleData(localeEn);

    const language =
      localStorage.getItem(this.LOCALE_STORAGE_KEY) ||
      this.translateService.getBrowserLang();

    this.setLocale(language);
  }

  setLocale(language: string | null | undefined) {
    if (
      !language ||
      !Object.values(SupportedLanguages)
        .map((sl) => sl.toString())
        .includes(language)
    ) {
      language = DefaultLanguage;
    }

    this.setSupportedLocale(language as SupportedLanguages);
  }

  private setSupportedLocale(language: SupportedLanguages) {
    this.translateService.use(language);
    localStorage.setItem(this.LOCALE_STORAGE_KEY, language);
    moment.locale(language);

    // console.log(moment.locale());
  }
}

import { NgxPermissionsService } from 'ngx-permissions';
import { firstValueFrom } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderStore } from '@do/app-loader';
import { LocaleService } from '@do/app-locale';
import { ToastService } from '@do/app-ui-kit';
import { marker } from '@do/ngx-translate-extract-marker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../auth.service';
import { ChangePasswordPopupContainer } from '../containers/change-password-popup.container';
import { ProfilePopupContainer } from '../containers/profile-popup.container';
import { AuthState } from './auth.store';

export function withStoreMethods() {
  return signalStoreFeature(
    { state: type<AuthState>() },
    withMethods(
      (
        store,
        authService = inject(AuthService),
        loaderStore = inject(LoaderStore),
        toastService = inject(ToastService),
        modalService = inject(NgbModal),
        permissionsService = inject(NgxPermissionsService),
        localeService = inject(LocaleService),
        router = inject(Router),
        translateService = inject(TranslateService)
      ) => {
        async function login(
          username: string,
          password: string,
          rememberMe: boolean,
          newPassword?: string
        ) {
          const actionId = loaderStore.showLoader();

          try {
            await firstValueFrom(
              authService.login(username, password, rememberMe, newPassword)
            );

            patchState(store, {
              userMustResetPassword: false,
              authError: '',
            });

            await router.navigateByUrl('/');
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 417) {
                patchState(store, {
                  userMustResetPassword: true,
                  loginForm: {
                    ...store.loginForm,
                    username: username,
                    password: password,
                    confirmPassword: '',
                    rememberMe: false,
                    newPassword: '',
                  },
                });
              } else {
                patchState(store, { authError: error.error.message });
              }
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
        }

        async function confirmUser(
          username: string,
          tmpPassword: string,
          rememberMe: boolean,
          newPassword: string,
          name: string,
          surname: string
        ) {
          const actionId = loaderStore.showLoader();

          try {
            await firstValueFrom(
              authService.confirmUser(
                username,
                tmpPassword,
                rememberMe,
                newPassword,
                name,
                surname
              )
            );

            patchState(store, {
              userMustResetPassword: false,
              authError: '',
            });

            toastService.successFeedback(
              translateService.instant(marker('Sign up completed'))
            );

            await router.navigateByUrl('/');
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(store, { authError: error.error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
        }

        async function forgotPassword(username: string) {
          const actionId = loaderStore.showLoader();

          try {
            await firstValueFrom(authService.forgotPassword(username));

            patchState(store, {
              resetPasswordEmailSent: true,
              authError: '',
            });
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(store, { authError: error.error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
        }

        async function resetPassword(token: string, newPassword: string) {
          const actionId = loaderStore.showLoader();

          try {
            await firstValueFrom(authService.resetPassword(token, newPassword));

            patchState(store, {
              authError: '',
            });

            toastService.successFeedback(
              translateService.instant(marker('Reset password completed'))
            );

            await router.navigateByUrl('/');
          } catch (error) {
            if (error instanceof HttpErrorResponse) {
              patchState(store, { authError: error.error.message });
            }
          } finally {
            loaderStore.hideLoader(actionId);
          }
        }

        async function changePassword() {
          const ref = modalService.open(ChangePasswordPopupContainer, {
            size: 'md',
          });

          const result = (await ref.result) as null | {
            password: string;
            newPassword: string;
          };

          if (result) {
            const actionId = loaderStore.showLoader();

            try {
              await firstValueFrom(
                authService.changePassword(result.password, result.newPassword)
              );

              patchState(store, {
                authError: '',
              });

              toastService.successFeedback(
                translateService.instant(marker('Password updated'))
              );
            } catch (error) {
              if (error instanceof HttpErrorResponse) {
                patchState(store, { authError: error.error.message });
              }
            } finally {
              loaderStore.hideLoader(actionId);
            }
          }
        }

        const loadLoggedUser = async () => {
          const actionId = loaderStore.showLoader();

          try {
            const loggedUser = await authService.mePromise();

            const permissions = (loggedUser?.role?.permissions || []).map(
              (rp) => rp.code.replace('_entity', '') || ''
            );
            // add role
            if (loggedUser?.role) {
              permissions.push(loggedUser?.role.code);
            }

            permissionsService.loadPermissions(permissions);
            localeService.setLocale(loggedUser.language);

            patchState(store, { loggedUser, authError: '', permissions });

            loaderStore.hideLoader(actionId);
          } catch (err) {
            loaderStore.hideLoader(actionId);
            authService.logoutLocal();
            setTimeout(
              async () => await router.navigateByUrl('/auth/login'),
              500
            );
          }
        };

        const sessionExpired = async (message?: string) => {
          loaderStore.forceHideLoader();
          authService.logoutLocal();
          toastService.errorFeedback(
            translateService.instant(message || marker('Session expired'))
          );
          modalService.dismissAll();
          setTimeout(() => {
            router.navigateByUrl('/auth/login');
          }, 500);
        };

        async function logout() {
          await firstValueFrom(authService.logout());
          patchState(store, {
            loggedUser: undefined,
            permissions: [],
            authError: '',
          });
          await router.navigateByUrl('/auth/login');
        }
        // const hasPermission = (permissions: string[]) => {
        //   return permissions
        //     .map((p) => permissionsService.getPermission(p))
        //     .every((p) => p != null);
        // };

        return {
          login,
          confirmUser,
          logout,
          loadLoggedUser,
          sessionExpired,
          // hasPermission,
          forgotPassword,
          resetPassword,
          changePassword,
          resetAuthError: () => {
            patchState(store, { authError: '' });
          },
          profile: async () => {
            const ref = modalService.open(ProfilePopupContainer);

            const proceed = (await ref.result) as null | {
              name: string;
              surname: string;
              language: string;
            };

            if (proceed) {
              const loader = loaderStore.showLoader();
              try {
                const updated = await authService.updateMePromise(proceed);
                if (updated) {
                  patchState(store, { loggedUser: updated });
                  localeService.setLocale(updated.language);
                  location.reload();
                  toastService.successFeedback(
                    translateService.instant(marker('Profile updated'))
                  );
                }
              } finally {
                loaderStore.hideLoader(loader);
              }
            }
          },
        };
      }
    )
  );
}

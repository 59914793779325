import { signalStore, withState } from '@ngrx/signals';

import { withStoreMethods } from './error.methods';

export interface ErrorState {
  errors: Error[];
  lastApiErrorMessage: string;
}

const initialState: ErrorState = {
  errors: [],
  lastApiErrorMessage: '',
};

export const ErrorStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  // actions & effects
  withStoreMethods()
);

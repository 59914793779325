import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'do-auth-password-hint',
  template: `
    <div translate class="password-hint">
      Password should be at least 8 characters long.<br />
      Must contain uppercase and lowercase letters.<br />
      Must contain at least a number.<br />
      Must contain at least a special character (!&#64;#$%^&*_-).
    </div>
  `,
  standalone: true,
  imports: [CommonModule, TranslateModule],
  styleUrl: 'password-hint.component.scss',
})
export class PasswordHintComponent {}

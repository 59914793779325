import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ROUTER_FACADE_TOKEN } from '@do/app-common';
import { FormsErrorPipe } from '@do/app-forms';
import {
  ButtonComponent,
  SelectFieldComponent,
  TextFieldComponent,
} from '@do/app-ui-kit';
import { SupportedLanguages } from '@do/common-interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AuthStore } from '../+state/auth.store';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TextFieldComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FormsErrorPipe,
    SelectFieldComponent,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ``,
  template: `
    <div class="modal-header">
      <span class="modal-title fw-bold" translate>Profile</span>

      <span
        class="material-symbols-outlined text-primary cursor-pointer"
        (click)="activeModal.close(null)"
      >
        cancel
      </span>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <do-text-field
            [label]="'Name' | translate"
            [formControl]="form.controls.name"
            [errors]="form.controls.name.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>
        <div class="mb-3">
          <do-text-field
            [label]="'Surname' | translate"
            [formControl]="form.controls.surname"
            [errors]="form.controls.surname.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>
        <div class="mb-3">
          <do-text-field
            [label]="'Email' | translate"
            [controlType]="'email'"
            [formControl]="form.controls.email"
            [errors]="form.controls.email.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-text-field>
        </div>
        <div class="mb-3">
          <do-select-field
            [label]="'Language' | translate"
            [formControl]="form.controls.language"
            [items]="supportedLanguages"
            bindValue="value"
            bindLabel="name"
            [errors]="form.controls.language.errors | formsError"
            [shouldShowError]="formIsSubmitted()"
          >
          </do-select-field>
        </div>
        <do-button
          class="d-block mt-4"
          [text]="'Change password' | translate"
          cssClasses="btn-secondary text-primary"
          (clicked)="authStore.changePassword()"
          translate
          >Change password</do-button
        >
      </form>
    </div>
    <div class="modal-footer">
      <do-button
        class="me-2"
        [cssClasses]="'btn-link'"
        [text]="'Cancel' | translate"
        (clicked)="activeModal.close(null)"
      ></do-button>

      <do-button
        class="me-2"
        [cssClasses]="'btn-primary'"
        [icon]="'done'"
        [text]="'Save' | translate"
        (clicked)="save()"
      ></do-button>
    </div>
  `,
})
export class ProfilePopupContainer {
  fb = inject(FormBuilder);
  routerFacade = inject(ROUTER_FACADE_TOKEN);
  activeModal = inject(NgbActiveModal);
  authStore = inject(AuthStore);

  formIsSubmitted = signal(false);

  supportedLanguages = Object.keys(SupportedLanguages).map((name) => {
    return {
      name,
      value: SupportedLanguages[name as keyof typeof SupportedLanguages],
    };
  });

  form = this.fb.group({
    name: ['', Validators.required],
    surname: ['', Validators.required],
    email: [
      { value: '', disabled: true },
      [Validators.required, Validators.email],
    ],
    language: new FormControl<string | null>(null, [Validators.required]),
  });

  constructor() {
    effect(() => {
      const loggedUser = this.authStore.loggedUser();

      if (loggedUser) {
        this.form.setValue({
          name: loggedUser?.name,
          surname: loggedUser?.surname,
          email: loggedUser.email,
          language: loggedUser.language,
        });
      }
    });
  }

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    this.formIsSubmitted.set(true);
    if (this.form.valid) {
      this.activeModal.close({
        name: this.form.value.name,
        surname: this.form.value.surname,
        language: this.form.value.language,
      });
    }
  }
}

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { marker } from '@do/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

import { ErrorStore } from './+state/error.state';
import { ErrorConfig } from './error.config';

export const SKIP_INTERCEPT_HEADER = 'skip-intercept-on-error';

export const apiErrorInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const store = inject(ErrorStore);
  const config = inject(ErrorConfig);
  const translateService = inject(TranslateService);

  if (
    request.headers.get(SKIP_INTERCEPT_HEADER) != null ||
    !request.url.includes(config.apiEndpoint)
  ) {
    const newHeaders = request.headers.delete(SKIP_INTERCEPT_HEADER);
    const newRequest = request.clone({ headers: newHeaders });
    return next(newRequest);
  }

  return next(request).pipe(
    // retry(1),
    catchError((error: HttpErrorResponse) => {
      let errorMessage = '';
      let errorLog: any;
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // Expired token error, do not intercept, let the error go
          return throwError(() => error);
        } else if (error.error) {
          // MANAGE ERRORS
          if (typeof error.error === 'string') {
            // client-side error
            errorMessage = `${error.error}`;
            store.addApiErrorMsg(errorMessage);
          } else if (error instanceof ArrayBuffer) {
            return throwError(() => error);
          } else {
            if (error.status === 500) {
              errorMessage = translateService.instant(
                marker(`Internal server error, check console for details`)
              );
              errorLog = error;
            } else if (error.status === 0) {
              errorMessage = translateService.instant(
                marker(`Network error, check your internet connection.`)
              );
            } else if (error.error.message) {
              errorMessage = error.error.message;
            } else if (error.message) {
              errorMessage = error.message;
            } else {
              errorMessage = `${translateService.instant('Error Code')}: ${
                error.status
              }\n ${translateService.instant('Error Message')}: ${
                error.message
              }`;
            }
            store.addApiErrorMsg(errorMessage, errorLog);
          }
        }
      }
      return throwError(() => new Error(errorMessage));
    })
  );
};
